import { useRive, Layout, Alignment } from "@rive-app/react-canvas";
import { useHistory } from "react-router-dom";

export const RiveToggle = () => {
  const { RiveComponent } = useRive({
    src: "/review.riv",
    artboard: "Artboard 2",
    stateMachines: "State Machine 1",
    layout: new Layout({
      alignment: Alignment.Left,
    }),
    autoplay: true,
  });

  return <RiveComponent />;
};

export default function App() {
  // Use useHistory hook inside the component
  const history = useHistory();

  const handleClick = () => {
    // Navigate to the /contact route using history.push
    history.push(`/contact`);
  };

  return (
    <div
      className="RiveContainerQuoteService"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <RiveToggle />
    </div>
  );
}
