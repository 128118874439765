/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright} from "@fortawesome/free-solid-svg-icons";
import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Testimonial from "../../../Pages/Home/Testimonial/Testimonial";

const Footer = () => {
  return (
    <section>
      <div className="mb-5">
        <Testimonial />
      </div>
      <div className="footercolors p-5 mt-5">
        <div className="container">
          <div className="row">
            <div className=" col-md-4">
      
              <br></br>
              <p>
                <FontAwesomeIcon className="mr-1" icon={faCopyright} />
                 
                 2025 © All Copyright Reserved 
              </p>
              <p>Parketlagnir ehf</p>
             
            </div>
         
            <div className="col-md-2">
  <p className="mb-4 mt-md-0 mt-4">Fyrirtæki</p>
  <p>
    <a href="/about" className="text-white">
      Um okkur
    </a>
  </p>
  <p>
    <a href="/projects" className="text-white">
      Nýjasta verkefnið
    </a>
  </p>
  <p>
    <a href="/terms" className="text-white">
      Skilmálar og skilyrði
    </a>
  </p>
  <p>
    <a href="/privacy" className="text-white">
      Persónuverndarstefna
    </a>
  </p>
</div>
<div className="col-md-2">
  <p className="mb-4 mt-md-0 mt-4">Flýtilegar tenglar</p>
  <p>
    <a href="/services" className="text-white">
      Þjónusta
    </a>
  </p>
  <p>
    <a href="/contact" className="text-white">
    Hafðu Samband
    </a>
  </p>
  <p>
    <a href="/review" className="text-white">
      Skrifa umsögn
    </a>
  </p>
</div>

            <div className="col-md-4 ">
          
              <a href="mailto:parketlagnir@parketlagnir.is" className="text-white d-flex align-items-center">
          <FaEnvelope size={30} />
          <p className="contact-text  ms-2">parketlagnir@parketlagnir.is</p>
        </a>
        <a href="tel:+3547848717" className=" text-white d-flex align-items-center">
          <FaPhone size={30} />
          <p className="contact-text ms-2">+354 784 8717</p>
        </a>
      
           
              <div>
                <a
                  href="https://www.facebook.com/profile.php?id=100090851592585"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faFacebookSquare}
                  />
                </a>
                <a
                  href="https://www.instagram.com/parketlagnir/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faInstagramSquare}
                  />
                </a>

                <a href="#" aria-label="Visa">
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faCcVisa}
                  />
                </a>
                <a href="#" aria-label="Mastercard">
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faCcMastercard}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
