import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Styles/ServiceCard.css";
import RiveButtonQuoteService from "../RiveButtonQuoteService";


const ServiceCard = ({ serviceInfo }) => {
  const { serviceName, serviceImg, serviceDesc, servicePrice, id } =
    serviceInfo;

  const renderPriceOrButton = () => {
    if (servicePrice === 0) {
      return (
        
        <RiveButtonQuoteService/>
      );
    } else {
      return (
        <>
          <Button
            as={Link}
            to={`/contact/${id}`}
            variant="success"
            className=" service-button"
          >
            Get a Free Quote!
          </Button>
        </>
      );
    }
  };

  return (
    <div className="section-title-mini  col-md-4   d-flex justify-content-center ">
      <Card border="light" className="servicecardd shadow" style={{ marginTop:"6rem", width: "28rem" , }}>
        <Card.Title className=" text-center maincolor py-4">
          <h2>
          {serviceName}</h2>
        </Card.Title>
        <div className="text-center">
         <a  href={`/contact/${id}`} >
          <Card.Img
            className="service-img"
            alt="service"
            variant="top"
            src={serviceImg}
          /></a>
        </div>
        <Card.Body>
          {renderPriceOrButton()}
          <Card.Text className=" fontweight">{serviceDesc}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ServiceCard;
