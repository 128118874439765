import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../Components/Shared/Loader/Loader";
import TestimonialCard from "../../../Components/TestimonialCard/TestimonialCard";
import { getReviews } from "../../../Services/UserServices";
import RiveButtonReview from "../../../Components/RiveButtonReview";

const Testimonial = () => {
  const [testimonialInfo, setTestimonialInfo] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getReviews().then((data) => setTestimonialInfo(data));
  }, []);

  const handleReviewRedirect = () => {
    history.push("/review");
  };

  return (
    <section className="mt-5 text-center sercent">
       <div className=" section-title w-full text-center">
       <h2>Hvað viðskiptavinir okkar segja</h2>
    
       </div>
      <div className="divider"></div>

      <div className="container">
        <div
          className="row justify-content-center mt-4 addreviewbutt"
          onClick={handleReviewRedirect}
        >
          <RiveButtonReview />
        </div>
        <div className="row justify-content-center">
          <div className="mb-5">
            {testimonialInfo.length === 0 ? <Loader /> : ""}
          </div>

          <div className="d-flex flex-wrap justify-content-between">
            {testimonialInfo.map((each, index) => (
              <TestimonialCard key={each._id || index} testimonialInfo={each} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
