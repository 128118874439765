import React, { Suspense, useState, useEffect, useRef } from "react";
import About from "../About/About";
import Services from "../PaintingServices/PaintingServices";
import TopBanner from "../TopBanner/TopBanner";
import Projects from "../Projects/Projects";
import FAQ from "../../../Components/Faq"

const Contactus = React.lazy(() => import("../Contactus/Contactus"));

const Home = () => {
  const [loadContactus, setLoadContactus] = useState(false);
  const contactusRef = useRef();

  useEffect(() => {
    // Capture the current value of the ref so it remains consistent for cleanup.
    const currentContactusElement = contactusRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Use the captured element for comparisons.
          if (entry.isIntersecting && entry.target === currentContactusElement) {
            setLoadContactus(true);
            observer.unobserve(currentContactusElement);
          }
        });
      },
      {
        rootMargin: "200px",
      }
    );

    if (currentContactusElement) {
      observer.observe(currentContactusElement);
    }

    return () => {
      if (currentContactusElement) {
        observer.unobserve(currentContactusElement);
      }
    };
  }, []);

  return (
    <div>
      <TopBanner />
      <div>
        <About />
      </div>
      <div>
        <Services />
        <div className="sectiontopdiv"></div>
      </div>
      <div>
        <Projects />
      </div>
      <div ref={contactusRef}>
        {loadContactus && (
          <Suspense fallback={<div>Loading Contact...</div>}>
            <Contactus />
          </Suspense>
        )}
      </div>
      <FAQ />
    </div>
  );
};

export default Home;
