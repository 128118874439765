import React from "react";
import { useRive, useStateMachineInput, Layout, Alignment } from "@rive-app/react-canvas";

const RiveToggle = ({ isDarkTheme, onToggle }) => {
  const STATE_MACHINE_NAME = "theme switch";
  const BOOLEAN_INPUT_NAME = "Dark Theme";

  const { RiveComponent, rive } = useRive({
    src: "/them_chenager.riv",
    artboard: "Artboard",
    stateMachines: STATE_MACHINE_NAME,
    layout: new Layout({
      alignment: Alignment.Left,
    }),
    autoplay: true,
  });

  const darkThemeInput = useStateMachineInput(rive, STATE_MACHINE_NAME, BOOLEAN_INPUT_NAME);


  React.useEffect(() => {
    if (darkThemeInput) {
      darkThemeInput.value = isDarkTheme;
    }
  }, [darkThemeInput, isDarkTheme]);

  const handleClick = () => {
    if (darkThemeInput) {
      darkThemeInput.value = !darkThemeInput.value;
    }

    onToggle(); 
  };

  return (
    <div onClick={handleClick} className="RiveContainerTheme">
      <RiveComponent />
    </div>
  );
};

export default RiveToggle;
