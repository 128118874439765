import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "../../../Components/VideoPlayer";
import RiveButton from "../../../Components/RiveButton";
import { db } from "../../../firebase.config";
import toast from "react-hot-toast";

// Utility function to chunk an array into groups of a given size
const chunkArray = (array, chunkSize) => {
  if (!Array.isArray(array)) return [];
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const TopBanner = () => {
  // We initialize the bannerData with placeholder default values.
  // This prevents the text container from changing height when actual data comes in.
  const [bannerData, setBannerData] = useState({
    title: " ", // non-breaking space as temporary content
    qualities: [],
    description: " ",
    mediaType: "image",
    mediaUrl: "",
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    // Try to get data from cache first
    db.collection("topbanner")
      .doc("main")
      .get({ source: "cache" })
      .then((doc) => {
        if (doc.exists) {
          setBannerData(doc.data());
        }
        setDataLoaded(true);
      })
      .catch(() => {
        // Fallback in case cache is not available
        db.collection("topbanner")
          .doc("main")
          .get()
          .then((doc) => {
            if (doc.exists) {
              setBannerData(doc.data());
            } else {
              toast.error("No banner data found.");
            }
            setDataLoaded(true);
          })
          .catch((error) => {
            toast.error("Failed to fetch banner data.");
            console.error(error);
            setDataLoaded(true);
          });
      });
  }, []);


  if (!dataLoaded) {
    return (
      <section className="mt-5" style={{ minHeight: "500px" }}>
        <div className="container">
          <div style={{ minHeight: "200px" }}></div>
          <div style={{ minHeight: "300px" }}></div>
        </div>
      </section>
    );
  }

  if (!bannerData || !bannerData.title.trim()) {
    return (
      <section className="mt-5" style={{ minHeight: "500px" }}>
        <div className="container">
          <p>Error: Banner data is unavailable.</p>
        </div>
      </section>
    );
  }

  const { title, qualities, description, mediaType, mediaUrl } = bannerData;

  return (
    <section className="mt-5">
      {/* Ensure the background and other spacing elements are always rendered */}
      <div className="spaceservices mobilelock" />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Text content with reserved height */}
          <div className="col-md-6" style={{ minHeight: "200px" }}>
            <div className="fontweight container">
              <h1 className="text-dangen fontweight1">{title}</h1>
              <p className="mt-4 mobilelock">
                {chunkArray(qualities, 3).map((chunk, chunkIndex) => (
                  <div key={chunkIndex}>
                    {chunk.map((quality, index) => (
                      <span key={index} className="d-inline-block mr-4">
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="green"
                          style={{ marginRight: "2px", marginLeft: "8px" }}
                        />
                        {quality}
                      </span>
                    ))}
                  </div>
                ))}
              </p>
              <p className="mt-4">{description}</p>
              <div className="col-12 mt-5 headerbutton">
                <Link to="/contact">
                  <RiveButton />
                </Link>
              </div>
            </div>
          </div>
          {/* Media content with reserved height */}
          <div className="col-md-6 mt-md-0 mt-5" style={{ minHeight: "300px" }}>
            <div className="text-center">
              {mediaType === "video" ? (
                <VideoPlayer src={mediaUrl} />
              ) : (
                mediaUrl && (
                  <img
                    src="/bannerImg.jpg"
                    alt={title || "Banner Image"}
                    className="HeaderImage"
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
