import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";

const FaqSection = () => {
  const [activeKey, setActiveKey] = useState("contact-acc-0");

  const faqData = {
    items: [
        {
          title: "Hvaða tegundir gólfefna bjóðið þið upp á?",
          text: "Við sérhæfum okkur í parketlagnir með fjölbreyttum og vönduðum gólfefnum. Þar á meðal eru harðparket, laminat, vínilgólf, flotgólf og herringbone-mynstur. Við leggjum áherslu á að velja efni sem henta þörfum og smekk hvers viðskiptavinar. Efnið er valið með hliðsjón af slitþoli, útliti, rakamótstöðu og notkun rýmisins – hvort sem um er að ræða heimili, verslunarrými eða skrifstofur."
        },
        {
          title: "Vinnið þið aðeins í Reykjavík?",
          text: "Við höfum aðsetur í Reykjavík og sinnum þar flestum verkefnum okkar. Hins vegar tökum við einnig að okkur verkefni víðs vegar um landið. Ef þú býrð utan höfuðborgarsvæðisins og þarft gólfþjónustu, er best að hafa samband og við metum hvort hægt sé að þjónusta þitt svæði. Markmið okkar er að veita hágæða parketlögn um allt land."
        },
        {
          title: "Hvernig óska ég eftir fríu verðmati?",
          text: "Þú getur fyllt út fyrirspurnarformið á vefsíðunni okkar eða haft beint samband í síma 784-8717. Við komum og skoðum aðstæður, ræðum efnisval og frágang, og sendum þér síðan skriflegt verðmat – algjörlega án skuldbindinga. Frítt verðmat er mikilvægur hluti af faglegri þjónustu okkar og hjálpar þér að taka upplýsta ákvörðun."
        },
        {
          title: "Hversu langan tíma tekur að leggja parket?",
          text: "Lengd framkvæmda fer eftir stærð rýmis, tegund gólfefnis og hvort undirbúningur sé nauðsynlegur, svo sem flotgólf. Flest verkefni taka 1–3 daga, en við metum tímaramma í hverju tilviki og tryggjum skýrt og raunhæft verkáætlun fyrirfram. Við vinnum hratt en án þess að fórna gæðum – fagmennska og snyrtilegur frágangur eru alltaf í forgangi."
        },
        {
          title: "Getið þið hjálpað mér að velja réttu gólftegundina?",
          text: "Já, við veitum persónulega ráðgjöf um efnisval og leggjum áherslu á að finna gólf sem henta bæði útliti og notkun. Við skoðum saman þarfir þínar, heimilisaðstæður eða tegund reksturs og gerum tillögur um parket, laminat, vínil eða annað sem hentar. Rétt gólfefni getur breytt rýminu – og við leiðbeinum þér að bestu lausninni."
        },
        {
          title: "Eru efni ykkar vatnsheld og barnavæn?",
          text: "Við notum hágæða gólfefni sem henta vel fyrir fjölskyldur með börn og gæludýr. Sérstaklega mælum við með vínil- og laminatgólfum fyrir rými með mikla umferð eða hættu á raka, svo sem forstofur, eldhús og bílskúra. Þessi efni eru auðveld í þrifum, slitsterk og veita fallega áferð sem endist."
        },
        {
          title: "Sjáið þið einnig um lista og frágang?",
          text: "Já, við sjáum um fullan frágang – þar á meðal uppsetningu á gólf- og vegglistum. Þetta er mikilvægur þáttur í því að útlit rýmisins verði heildstætt og faglegt. Við veljum lista sem passa við gólfefnið og setjum þá upp af nákvæmni. Rétt frágangur lyftir heildarútliti gólfsins og eykur verðgildi eignarinnar."
        },
        {
          title: "Bjóðið þið ábyrgð á vinnunni?",
          text: "Við stöndum 100% á bak við okkar vinnu og öll verk sem við framkvæmum eru með ábyrgð. Ef eitthvað kemur upp eftir lagningu, þá skoðum við það tafarlaust og finnum lausn. Gæði, áreiðanleiki og traust viðskiptasamband eru hornsteinar í starfsemi Parketlagnir – og við viljum að þú njótir nýja gólfsins með fullvissu um að það sé vel unnið."
        }
      ],      
  };

  return (
    <section className="contact-faqs">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="acc2">
              <Accordion
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onSelect={(e) => setActiveKey(e)}
              >
                {faqData.items.map((item, key) => {
                  const eventKey = `contact-acc-${key}`;
                  const isActive = activeKey === eventKey;
                  return (
                    <Accordion.Item
                      key={`faq-${key}`}
                      eventKey={eventKey}
                      className={`faq-item ${isActive ? "active-faq" : ""}`}
                    >
                      <Accordion.Header className="faq-header">
                        {item.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{item.text}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
