import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore"; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyCG5BCEXGV7BARACIFRhMzylLMTgieuaGE",
  authDomain: "parketlagnir.firebaseapp.com",
  projectId: "parketlagnir",
  storageBucket: "parketlagnir.firebasestorage.app",
  messagingSenderId: "460234394697",
  appId: "1:460234394697:web:5c834a7322cfb3214ac398",
  measurementId: "G-YZBS7PDVC9"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();
const db = firebase.firestore(); // Initialize Firestore

export { storage, db }; // Export storage and Firestore database
export default firebaseConfig;
