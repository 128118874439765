import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdAddIcCall } from "react-icons/md";
import ThemeToggle from "../../Rivetoggle";
import logoLight from "../../../assets/logo-light.svg";
import logoDark from "../../../assets/logo-dark.svg";

const TopBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(() => localStorage.getItem('theme') === 'dark');
  const navbarRef = useRef(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 5);
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 768);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (navbarRef.current) {
      document.body.style.paddingTop = `${navbarRef.current.offsetHeight}px`;
    }
  }, [scrolled]);

  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    document.body.classList.toggle("dark-theme", newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const logoSrc = isDarkTheme ? logoDark : logoLight;
  const handleNavClick = () => setExpanded(false);

  return (
    <Navbar
      ref={navbarRef}
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      className={`fixed-top ${scrolled ? "navbarCustom" : "navbarCustom1"}`}
      style={{ transition: "all 0.3s ease-out" }}
    >
      <div className="navbar-container">
        <ThemeToggle isDarkTheme={isDarkTheme} onToggle={toggleTheme} />
        <a href="/">
          <img
            onClick={handleNavClick}
            src={logoSrc}
            alt="Parketlagnir logo"
            className="logo"
            width="105"
            height="80"
          />
        </a>
      </div>

      <div
        className="custom-toggler"
        onClick={() => setExpanded(!expanded)}
        aria-controls="basic-navbar-nav"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24">
          <path
            className="menu-toggle"
            d="M4.5 17q-.213 0-.356-.144Q4 16.712 4 16.5t.144-.356Q4.288 16 4.5 16h10.423q.213 0 .356.144t.144.357q0 .212-.144.356q-.143.143-.356.143zm14.438-1.258l-3.196-3.177Q15.5 12.323 15.5 12q0-.323.242-.565l3.196-3.158q.14-.14.345-.15q.204-.01.363.15q.16.16.16.354q0 .194-.16.354L16.573 12l3.073 3.035q.16.134.16.34t-.16.367q-.134.14-.34.14t-.368-.14M4.5 12.5q-.213 0-.356-.144Q4 12.212 4 12t.144-.356q.144-.143.356-.143h7.577q.212 0 .356.144t.144.357t-.144.356q-.144.143-.356.143zm0-4.5q-.213 0-.356-.144T4 7.499q0-.212.144-.356Q4.288 7 4.5 7h10.423q.213 0 .356.144q.144.144.144.357q0 .212-.144.356q-.143.143-.356.143z"
          />
        </svg>
      </div>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="custom-nav-ul ms-auto mr-5">
          {[
 
        { to: "/", label: "Heim" },
        { to: "/about", label: "Um okkur" },
        { to: "/services", label: "Þjónusta" },
        { to: "/projects", label: "Verk" }, // Or "Myndasafn" if appropriate
        { to: "/contact", label: "Hafa samband" },
        { to: "/contact", label: "Fá tilboð" }
     
      
          ].map(({ to, label }) => (
            <Nav.Link
              key={to}
              as={Link}
              to={to}
              className="custom-nav-a mr-4"
              onClick={handleNavClick}
              alt={`Parketlagnir theme ${label}`}
            >
              {label}
            </Nav.Link>
          ))}
        </Nav>

        {(isLargeScreen && scrolled) || !isLargeScreen ? (
          <a aria-label="Phone Parketlagnir Reykjavik" href="tel:+3547848717">
            <MdAddIcCall size={isLargeScreen ? 35 : 32} className="headercall text-dangen" />
          </a>
        ) : null}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopBar;
